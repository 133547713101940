.wpb_wrapper {
  font-size: 15px;
  color: #666666;
  font-family: 'Dosis';
  line-height: 1.5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}
