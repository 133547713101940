.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* our partners page */
.our-partners-cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 150px 0;
  gap: 30px;
  row-gap: 50px;
}

.our-partner-card {
  padding: 8px 15px;
  border-radius: 5px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  width: 30%;
  cursor: pointer;
  border: 1px solid transparent;
  /* filter: grayscale(1); */
  transition: all 0.3s;
}

.our-partner-card:hover {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-color: #ccc;
  /* filter: grayscale(0); */
  transform: scale(1.05);
}

.our-partner-logo {
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.our-partner-card img {
  max-height: 40px;
}

.our-partner-textContainer {
  min-height: 40px;
  position: relative;
}

.our-partner-card:hover .our-partner-heading {
  visibility: hidden;
  opacity: 0;
}

.our-partner-heading {
  font-size: 26px;
  color: #787878;
  position: absolute;
  top: 0;
  text-align: center;
  margin: 0;
  width: 100%;
}

.our-partner-heading-active {
  visibility: hidden;
  opacity: 0;
}

.our-partner-card:hover .our-partner-heading-active {
  visibility: visible;
  opacity: 1;
}

/* about page */
.about-page-slider-section {
  position: relative;
}
.about-page-slider-headingContainer,
.about-page-slider-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  font-size: 50px;
  font-weight: bold;
  color: #fff;
  transform: translate(-50%, -50%);
}

.about-page-slider-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 5;
}

.about-page-slider-imageContainer {
  width: 100vh;
  max-height: 700px;
}
.about-page-slider-imageContainer img {
  width: 100%;
  height: 100%;
  object-position: bottom;
}

/*  */
/* gallery page */
.gallery-header-container {
  background-image: url(https://images.squarespace-cdn.com/content/v1/597d2db2b8a79b2cf84cfefc/1519489931733-WNWDKJMIUBOOF88EGKHZ/gallery.jpg);
}

.section-gallery {
  margin: 100px 0;
  min-height: 50vh;
}

.gallery-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  row-gap: 40px;
}
.gallery-grid-container .gallery-grid-item {
  text-align: left;
}

.gallery-grid-item-heading {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 5px;
}

.gallery-grid-container .gallery-grid-item-imageContainer {
  width: 100%;
  overflow: hidden;
  aspect-ratio: 1.9/1.07;
  /* height: 340px; */
  /* cursor: pointer; */
}

.gallery-grid-container .gallery-grid-item img,
.gallery-grid-container .gallery-grid-item video {
  width: 100%;
  height: 100%;
  border: 1px solid #dddddd;
  border-radius: 3px;
  transition: all 0.5s ease-out;
  /* max-height: 360px; */
  /* aspect-ratio: 1.9/1; */
}
.gallery-grid-container .gallery-grid-item video {
  /* object-fit: cover; */
}

.gallery-grid-container .gallery-grid-item-imageContainer:hover img {
  /* transform: scale(1.03); */
}

.gallery-grid-container .gallery-grid-item p {
  padding: 0;
}

.gallery-loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}
.gallery-loadingContainer svg {
  width: 50px;
  height: 50px;
}

@media (max-width: 1024px) {
  .gallery-grid-container {
    gap: 15px;
    row-gap: 30px;
  }
  .gallery-grid-item-heading {
    font-size: 18px;
    margin-bottom: 2px;
  }
}

@media (max-width: 640px) {
  .section-gallery {
    margin-top: 60px;
  }

  .gallery-grid-container {
    grid-template-columns: 1fr;
  }
  .gallery-grid-item-heading {
    font-size: 16px;
    margin-bottom: 2px;
  }
  .gallery-grid-container .gallery-grid-item p {
    font-size: 14px;
  }
}

/*  */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*new service css*/

.nssplSection {
  margin-top: 30px;
}

@media (max-width: 992px) {
  .nssplSection {
    width: 100%;
  }
}
.containerFluid {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}

.pt60 {
  padding-top: 60px;
}

@media (max-width: 767px) {
  .pt60 {
    padding-top: 0px;
  }
}
.consulting__title {
  font-size: 1.8rem;
  font-weight: 600;
  margin: 0 0 0.7em;
  text-transform: capitalize;
  text-align: center;
  line-height: 2;
  color: black;
}

.consulting__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.consulting__container.odd {
  justify-content: space-between;
}

.consulting__box {
  display: flex;
  align-items: flex-start;
  width: 50%;
  max-width: 520px;
  padding: 20px;
}
@media (max-width: 540px) {
  .consulting__box {
    width: 100%;
    padding: 10px;
  }
}
.consulting__box.singleRow {
  width: 100%;
  max-width: unset;
  margin-bottom: 20px;
}
.consulting__box.cyberRow {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.consulting__boxImage {
  width: 65px;
  height: 65px;
}

.consulting__boxImage img {
  width: 65px;
  height: 65px;
  max-width: 65px;
}

.consulting__textArea {
  padding-left: 15px;
  text-align: left;
}

.consulting__boxHeading {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 0.7em;
  text-transform: capitalize;
  color: black;
}

.consulting__boxContent {
  font-family: "Asap", poppins;
  color: #687188;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  /* margin-left: 50px; */
}

.universal__title {
  font-size: 2rem;
  font-weight: 600;
  margin: 0 0 0.7em;
  text-transform: capitalize;
  text-align: center;
  line-height: 2;
  color: black;
}

.universal__box {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  padding-top: 20px;
  margin-left: 35px;
}
@media (max-width: 992px) {
  .universal__box {
    margin-left: 0px;
  }
}
.universal__box.reverse {
  flex-direction: row-reverse;
}

.universal__imageContainer {
  min-width: 370px;
  /* width: 100%; */
  height: auto;
  object-fit: cover;
  /* padding-left: 20px; */
}
.cyberSecurity__sideImg {
  min-width: 600px;
}
.universal__imageContainer img {
  width: 100%;
  height: 100%;
}

@media (max-width: 992px) {
  .universal__imageContainer {
    min-width: 100%;
    display: flex;
  }
}
.universal__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5px;
}

.universal__listItem {
  width: 44%;
  margin-bottom: 0.7em;
  padding-bottom: 1px;
  text-align: left;
  margin-left: 5px;
  font-size: 14px;
  color: #687188;
  display: flex;
  align-items: flex-start;
  font-weight: 500;
}

.universal__listItem.singleRow {
  width: 100%;
  margin-bottom: 20px;
}

.universal__listItem span {
  font-size: 18px;
  color: #0b5be0;
  text-align: center;
  margin-right: 10px;
  padding-top: 2px;
}

.greenBox__title {
  font-size: 2rem;
  font-weight: 600;
  margin: 0 0 0.7em;
  text-transform: capitalize;
  text-align: center;
  line-height: 2;
  color: black;
}
.heading__title {
  font-weight: 600;
  text-align: center;
  width: 100%;
}
@media (max-width: 767px) {
  .heading__title {
    font-size: 1.5rem;
    line-height: 1.3;
  }
}

.greenBox__boxList {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  justify-content: space-evenly;
}

.greenBox__box {
  width: 45%;
  box-shadow: 0px 0px 10px 0px rgb(206, 206, 206);
  background-color: #fff;
  margin: 0 15px 40px;
  border-radius: 10px;
  height: 320px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .greenBox__box {
    width: 100%;
    margin: 0 0px 20px;
    height: auto !important;
  }
}
.greenBox__titleImage:before {
  font-size: 3.33333rem;
  background: -webkit-linear-gradient(#0b5be0, #06347f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.greenBox__titleText {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0.3em 0 0.7em;
  text-transform: capitalize;
  padding-left: 5px;
  color: black;
}

.greenBox__boxTitle {
  background: linear-gradient(to bottom right, #3b843b, rgb(252, 252, 144));
  padding: 15px 10px;
  display: flex;
  align-items: center;
}

.greenBox__content {
  padding: 15px 20px 0;
  margin-bottom: 0.7em;
  text-align: left;
  margin-left: -9px;
  font-size: 14px;
  color: #687188;
  line-height: 1.7em;
}

.greenBox__contentList {
  max-height: 150px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 10px;
}

.greenBox__contentListItem {
  display: flex;
  align-items: flex-start;
  font-weight: 500;
  font-size: 14px;
  color: #687188;
  margin-bottom: 5px;
}

.greenBox__contentListItem span {
  font-size: 18px;
  color: #0b5be0;
  text-align: center;
  margin-right: 10px;
  padding-top: 5px;
}

@media (min-width: 1200px) {
  .container {
    width: 1270px;
  }
}

@media (min-width: 992px) {
  .hide-desktop {
    display: none;
  }
}

@media (max-width: 992px) {
  .universal__box,
  .universal__box.reverse {
    flex-direction: column;
    width: 100%;
  }

  .universal__list {
    margin-top: 20px;
    margin-left: 0px;
    width: 98%;
  }

  .universal__listItem {
    justify-content: flex-start;
    width: 100%;
  }

  .greenBox__box {
    height: 400px;
  }

  .greenBox__contentList {
    max-height: unset;
  }

  .universal__listItem.singleRow {
    justify-content: flex-start;
  }
}

@media (max-width: 991px) {
  .hide-phone {
    display: none;
  }
}

.consulting__contentList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 10px;
}
.consulting__contentListItem {
  display: flex;
  align-items: flex-start;
  font-weight: 500;
  font-size: 14px;
  color: #687188;
  margin-bottom: 5px;
  text-align: left;
}
.consulting__contentListItem span {
  font-size: 18px;
  color: #0b5be0;
  text-align: center;
  margin-right: 10px;
  padding-top: 2px;
}

.stepperNew__title {
  font-size: 2rem;
  font-weight: 600;
  margin: 0 0 0.7em;
  text-transform: capitalize;
  text-align: center;
  line-height: 2;
  color: black;
}
.stepperNew__text {
  margin-bottom: 0.7em;
  padding-bottom: 1px;
  text-align: left;
  font-size: 14px;
  color: #687188;
  font-weight: 600;
}

.showcase__title {
  font-size: 2rem;
  font-weight: 600;
  margin: 0 0 0.7em;
  text-transform: capitalize;
  text-align: center;
  line-height: 2;
  color: black;
}
.showcase__boxContainer {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .showcase__boxContainer {
    justify-content: center;
  }
}
.showcase__box {
  max-width: 320px;
  width: 33%;
  box-shadow: 0px 0px 10px 0px rgb(206, 206, 206);
  border-radius: 5px;
  overflow: hidden;
  min-height: 550px;
}
@media (max-width: 991px) {
  .showcase__box {
    width: 100%;
    max-width: 500px;
    margin-bottom: 30px;
  }
}
.showcase__image {
  max-width: 100%;
  min-height: 220px;
}
.showcase__image img {
  width: 100%;
}
.showcase__content {
  padding: 10px;
}
.showcase__contentTitle {
  color: black;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0 1em;
  text-align: center;
  min-height: 60px;
}
.showcase__contentText {
  color: #687188;
  margin-bottom: 0.7em;
  padding-bottom: 1px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.mtb30 {
  margin: 30px 0;
}

.universal__shortTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0 0 0.7em;
  text-transform: capitalize;
  text-align: center;
  line-height: 1.3;
  color: black;
}
.universal__text {
  margin-bottom: 0.7em;
  padding-bottom: 1px;
  text-align: left;
  font-size: 14px;
  color: #687188;
  font-weight: 600;
}

.showcase__box.short {
  min-height: 330px;
}

.mt-30 {
  margin-top: 60px;
}

@media (max-width: 992px) {
  .mt-30 {
    margin-top: 0px;
  }
}
@media (max-width: 992px) {
  .MuiTab-root {
    max-width: 100px !important;
  }
  .MuiTab-root span {
    font-weight: 700 !important;
  }
}

.ml-0 {
  margin-left: 0 !important;
}

.max-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 55px 55px;
  text-align: center;
  border-radius: 10px;
}
.wpb_wrapper_list {
  width: 80%;
  margin-top: 20px;
  margin-left: 150px;
  display: flex;
  justify-content: space-around !important;
  align-items: flex-start !important;
}

@media (max-width: 992px) {
  .max-list {
    padding: 55px 25px;
  }
  .wpb_wrapper_list {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0px;
  }
}

.vision-mission-img {
  width: 100%;
  height: 250px;
}

.vision-mission-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.procurement-img {
  max-width: 250px;
  margin: 0 auto;
}

.cyberSecurity__small-ul {
  margin-left: 20px !important;
}
.cyberSecurity__small-ul span {
  font-size: 14px;
}
.cyberSecurity__big-ul {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-top: 50px;
  margin-left: 50px;
}
.cyberSecurity__features-ul {
  width: 40vw;
  display: flex;
  justify-content: space-between;
}

.cyberSecurity__features-ul li {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.cyberSecurity__features-ul li img {
  height: 38px;
}
.cyberSecurity__features-ul li i {
  color: #0b5be0;
  margin-top: 10px;
}
.casbpng__style {
  width: 100%;
  /* max-height: 466px; */
  object-fit: cover;
  padding-left: 20px;
  margin-top: 0px;
}
.cyberSecurity__features-ul p {
  font-weight: 600;
  font-size: 14px;
}
.dlpPng {
  /* margin-top: 0px !important; */
  width: 70% !important;
}
.edrPng {
  /* width: 10% !important; */
  /* height: 400px !important; */
}
.security_std {
  width: 90%;
  margin: 0 auto !important;
}

.casbpng {
  margin-left: -90px;
}
.cyber_intro {
  display: flex;
  justify-content: flex-start;
}
.cyber_intro h2 {
  font-size: 1.4rem !important;
  margin-bottom: 0px !important;
  margin-left: -5px !important;
  text-transform: none !important;
}

.cyber_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cyber_container-col1 {
  width: 53%;
}
.cyber_container-col1 p {
  margin-top: 13px;
}
.marigin-left {
  margin-left: 5px !important;
}
.margin-para {
  margin-left: 40px;
  text-align: center;
}
.text-center {
  text-align: center;
}
.top__heading {
  width: 84%;
  text-align: center;
}
.mt30 {
  margin-top: 30px;
}
.ml-30 {
  margin-left: -30px;
}
.ml5 {
  margin-left: 5px !important;
}
@media (max-width: 991px) {
  .cyberSecurity__small-ul,
  .consulting__boxContent {
    margin-left: 0px !important;
  }
  .cyberSecurity__big-ul {
    width: 100% !important;
    margin-left: 0px !important;
  }
  .consulting__box.cyberRow {
    max-width: 100vw;

    margin-bottom: 0;
  }
  .cyberSecurity__features-ul {
    width: 100% !important;
  }
  .casbpng__style {
    padding: 0 !important;
  }
  .dlpPng {
    width: 100% !important;
  }
  .casbpng {
    margin-left: 0;
  }
  .cyber_container {
    flex-direction: column;
  }
  .cyber_container-col1 {
    width: 96%;
  }
  .marigin-left {
    margin-left: -20px !important;
  }
  .top__heading {
    width: 100%;
    text-align: center;
  }
  .ml-30 {
    margin-left: 0px;
  }
  .ml5 {
    margin-left: 15px !important;
  }
}
.dflex {
  display: flex;
  width: 200px;
  justify-content: space-between;
}
@media (max-width: 420px) {
  .marigin-left {
    margin-left: 0px !important;
  }
}

@media (max-width: 300px) {
  .dflex {
    flex-direction: column;
  }
}
